<template>
<div class="bg">
   <img src="../assets/yiliao/1.png" alt="">
</div>
</template>
<script>
export default {
  data(){
    return{
    }
  }
}
</script>
<style scoped>
.bg{
  /*height:calc(100vh);*/
  margin: 0 auto;
  display: flex;
  justify-items: center;
  flex-direction: column;
  width: 80vw;
  height: 100vh;
}
img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

</style>
